@import "../../Utilities/Vars.scss";

hr {
    margin: 0;
    margin-bottom: 0.4vh;
    padding: 0;
    height: 0.3vh;
    background-color: $--letter-stroke-color;
    opacity: 0.7;
    border-radius: 15px;
    border: none;
}

@media only screen and (max-width: $--mobile-max-width) {
    hr {
        margin: 2px;
    }
}
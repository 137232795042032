@import "../../Utilities/Vars.scss";

.row {
    display: flex;
    align-items: center;
    min-height: calc($--buttons-height-desktop + 1vh);
    .cells-wrapper {
        display: flex;

        .cell {
            width: $--buttons-width-desktop;
            height: $--buttons-height-desktop;
            margin: $--margin-size;
            border-radius: 5px;
            border: 2px solid $--button-border-color;
            cursor: pointer;
            transition: transform 0.1s;
        }
    }

    .feedback-wrapper {
        width: calc(2*($--buttons-width-desktop + 4px));
        height: calc(($--buttons-height-desktop + 4px));
        display: flex;
        padding: $--margin-size;
        opacity: 0.9999999;

        .feedback-item {
            font-size: 2.5rem;
            font-family: "Poetsen One", sans-serif;
            font-weight: 500;
            font-style: normal;
            
            -webkit-text-stroke-width: 1.5px;
            -webkit-text-stroke-color: $--letter-stroke-color;

            width: $--buttons-width-desktop;
            height: $--buttons-height-desktop;
            border-radius: 5px;
            border: 2px solid #00000000;

            &.correct {
                color: $--color-correct;
            }

            &.incorrect {
                color: $--color-incorrect;
            }
        }

        &.disabled {
            opacity: 0.7;
            pointer-events: none;
        }
    }
}





@media only screen and (max-width: $--mobile-max-width) {
    .row {
        min-height: calc($--buttons-height-mobile + 1vh);
        margin-top: 1vh;
        margin-bottom: 1vh;
        width: auto !important;

        .cell {
            // width: calc(100vw/10) !important;
            height: unset !important;
            border-width: 1.5px !important;
            aspect-ratio: 1 !important;
        }

        .feedback-wrapper {
            width: calc(2*($--buttons-width-mobile + 4px));
            height: 100%;
            .feedback-item {
                -webkit-text-stroke-width: 1px;
                width: $--buttons-width-mobile !important;
                height: $--buttons-height-mobile !important;
                font-size: 5vw;
            }
        }

    }
    
}
@import "../../Utilities/Vars.scss";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .container {
        background: white;
        padding: 1vw;
        height: 60%;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        top: -10%;
        position: relative;
        text-align: center;
        font-family: 'Arial', sans-serif;
        color: #333;
        width: calc($--app-width-desktop * 0.7);

        .close-button {
            position: absolute;
            top: 1vh;
            right: 1vw;
            background: none;
            border: none;
            cursor: pointer;
            color: #ff0000;
        }

        .title {
            margin-bottom: 20px;
            margin-top: 1vh;
            font-size: 8vw;
            font-family: "Danfo", serif;
            font-weight: 100;
        
            letter-spacing: 0.1rem;
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: $--letter-stroke-color;
            cursor: pointer;
            text-shadow: $--shadow-size $--shadow-size 0px black;
        }

        .option {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        
            .label {
                font-size: 3vw;
                margin-right: 10px;
                flex: 1;
                text-align: left;
                -webkit-text-stroke-width: 2px;
                -webkit-text-stroke-color: $--letter-stroke-color;
                // text-shadow: 
        
                letter-spacing: .1rem;
                font-family: "Montserrat Alternates", sans-serif;
                color: #fee6e3;
                font-weight: 800;
                font-style: normal;
            }
        }
        
    }
}






.checkbox {
    flex: 0;
    transform: scale(1.2);
}

@media only screen and (max-width: $--mobile-max-width) {

    .container {
        width: 90%;
        height: 80%;
        top: 0%;
        .title {
            font-size: 10vw;
            margin-top: 4vh;
        }
        .label {
            font-size: 5vw !important;
        }
    }
}
//DESKTOP
$--buttons-width-desktop: 5vw;
$--buttons-height-desktop: 5vw;

$--app-width-desktop:100vw;

//MOBILE
$--mobile-max-width: 950px;
//DESKTOP
$--buttons-width-mobile: 7vw;
$--buttons-height-mobile: 8vw;


//ALL
// $--shadow-size: 6px;
// $--margin-size: 6px;
$--shadow-size: .5vw;
$--margin-size: .5vw;



//COLORS:
$--letter-stroke-color: #111111;
$--button-border-color: #111111;

$--color-correct: #3BC14A;
$--color-incorrect: #BC412B;
@import url("https://fonts.googleapis.com/css2?family=Danfo&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap');
@import "./Vars.scss";

.button-submit {
    transition: transform 0.1s;  
    font-size: 2vw;
    font-family: "Danfo", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "ELSH" 0;
    width: 10vw;
    align-items: center;
    background-color: white;
    border: 2px solid $--button-border-color;
    border-radius: $--shadow-size;
    box-sizing: border-box;
    color: $--button-border-color;
    cursor: pointer;
    display: flex;
    height: calc($--buttons-height-desktop);
    justify-content: center;
    line-height: 24px;
    max-width: 100%;
    padding: 0 25px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-submit:hover {
    transform: none !important;
}

.button-submit:focus {
    outline: none;
}

.button-submit:after {
    background-color: $--button-border-color;
    border-radius: $--shadow-size;
    content: "";
    display: block;
    height: calc($--buttons-height-desktop );
    left: 0;
    width: 100%;
    position: absolute;
    z-index: -1;
    top: -2px;
    transform: translate($--shadow-size, $--shadow-size);
    transition: transform 0.2s ease-out;
}


.button-submit:hover:after {
    transform: translate(0, 0);
}

.button-submit:active {
    background-color: #ffdeda;
    outline: 0;
}

.button-submit:hover {
    outline: 0;
}

.no-select {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.button-disabled {
    opacity: 0.4;
    pointer-events: none;
}


@media only screen and (max-width: $--mobile-max-width) { 
    .colors-table-buttons {
        height: calc($--buttons-height-mobile);
        margin-top: 1vh;
        margin-bottom: 1vh;
    }
    .game-table {
        max-width: 100vw;
        margin: 10px;

        .button-submit {
            width: 100%;
            height: $--buttons-height-mobile !important;
            font-size: 4vmin;
        }

        .button-submit:after {
            height: $--buttons-height-mobile !important;
        }

    }
    .colors-table-buttons-button{
        width: $--buttons-width-mobile !important;
        height: $--buttons-height-mobile !important;
    }
}
@import "../../Utilities/Vars.scss";

.end-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000 !important;
}

.end-screen-container {
    background: white;
    padding: 1vw;
    height: 90%;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: center;
    font-family: 'Arial', sans-serif;
    overflow: scroll;
    color: #333;
    width: calc(70% - 2vw);
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #ff0000;
}

.end-screen-title {
    margin-bottom: 3vh;
    font-size: 6vw;
    margin-top: 1vh;
    font-family: "Danfo", serif;
    font-weight: 100;
    letter-spacing: 0.1rem;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    cursor: pointer;
    text-shadow: $--shadow-size $--shadow-size 0px black;
}

.history-container {
    background-color: #f0f0f0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    margin: auto;
    max-height: 25vh;
    padding: 6px;
    overflow: auto;

    /* Custom Scrollbar */
    &::-webkit-scrollbar {
        width: 16px;
    }

    &::-webkit-scrollbar-track {
        background: white;
        border: 3px solid black;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: black;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #111;
    }

    .history-container-guess {

        .history-container-guess-color {
            width: $--buttons-width-desktop;
            height: $--buttons-height-desktop;
            display: inline-block;
            margin: 2px;
        }
    }
}

.button-group {
    width: 90%;
    margin: auto;
    padding-top: 3vh;
    padding-bottom: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    .button-group-button {
        width: 60%;
        margin: .2vmax;
    }
    .button-group-text{ 
        margin-bottom: 2vh;
    }
    
    .formatted-text { 
        font-family: "Poetsen One", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 2vw;
        color: $--letter-stroke-color;
        width: 100%;
    }
}



.correct-record {
    border: 5px solid;
    border-color: $--color-correct;
}

.incorrect-record {
    border: 5px solid;
    border-color: $--color-incorrect;
}

.icon-coffee {
    width: 4vw !important;
    height: 100% !important;
}

@media only screen and (max-width: $--mobile-max-width) { 
    .button-group {
        width: 95%;
        padding: 1vh 1vw 1vh;

    }
    .end-screen-container {
        width: 90%;
        height: 70%;
        top: 0%;
    }

    .end-screen-title {
        font-size: 12vw;
    }

    .formatted-text {
        font-size: 4vw !important;
    }

    .button-group-button{
        width: 95% !important;
        height: 7vw !important;
        font-size: 5vw !important;
    }

    .history-container-guess-color {
        width: calc($--buttons-width-mobile * 0.70) !important;
        height: calc($--buttons-height-mobile * 0.70) !important;
    }

    .icon-coffee {
        width: 7vw !important;
    }

}
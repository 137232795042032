@import "../../Utilities/Vars.scss";

body {
    font-family: 'Arial', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}

.background-animation {
    animation: background-colors 120s infinite !important;
}

.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    overflow-y: scroll;
    scrollbar-width: none;
    width: $--app-width-desktop;
    background-color: #add8e688;
}

.app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 52%;
    height: auto;
}

.title {
    font-size: 7vw;
    margin-bottom: 0px;
    margin-top: 0px;
    text-transform: uppercase;

    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: $--letter-stroke-color;

    font-family: "Danfo", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "ELSH" 0;
    color: #24b8b3CC;

    text-shadow: $--shadow-size $--shadow-size 0px black;
}

.rainbow-letter {
    display: inline-block;
    animation: rainbow-colors 7s  infinite;
}

.hover-effect-button:hover {
    transform: scale(105%);
}

@keyframes background-colors {
0% {
        background-color: #3357FF33;
    }

    14% {
        background-color: #24b8b333;
    }

    28% {
        background-color: #33FF5733;
    }

    42% {
        background-color: #FF833333;
    }

    57% {
        background-color: #FF573333;
    }

    71% {
        background-color: #FF333333;
    }

    85% {
        background-color: #8D33FF33;
    }

    100% {
        background-color: #6045FF33;
    }
}

@keyframes rainbow-colors {
    0% {
        color: #3357FF;
    }

    14% {
        color: #24b8b3;
    }

    28% {
        color: #33FF57;
    }

    42% {
        color: #FF8333;
    }

    57% {
        color: #FF5733;
    }

    71% {
        color: #FF3333;
    }

    85% {
        color: #8D33FF;
    }

    100% {
        color: #6045FF;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.icon-size-coffee{
    width: 100px !important;
    height: 100px !important;
    margin: 0 auto;
}

.icon-size-setting{
    margin: 0 auto;
    width: 50px;
    height: 50px;
}

.icon-setting{
    width: 100px !important;
}

@media only screen and (max-width: $--mobile-max-width) {
    .App {
        overflow-x: hidden;
        width: 100vw;
    }

    .app-header {
        width: 100% !important;
    }

    .title {
        width: 100% !important;
        font-size: 14.2vw;
    }

    .icon-size-coffee {
        width: 16vw !important;
        height: 16vw !important;
        margin: 0 auto;
    }

    .icon-size-setting {
        margin: 0 auto;
        width: 8vw !important;
        height: 8vw !important;
    }

}